import type {Tag} from "@uxu/utils/common/types";
import {createSlug} from "@uxu/utils/common/slugs";
import {createSlugForType} from "../../function";
import type {AdapterTagDataProps} from "./types";

export function adapterTagData(tag?: AdapterTagDataProps): Tag {
    return ({
        id: tag?.id || "",
        title: tag?.attributes?.title ?? "",
        slug: `${createSlugForType('tag')}/${tag?.id || ""}/${createSlug(tag?.attributes?.title || "")}`,
    });
}